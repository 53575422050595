import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import { Link, useNavigate } from 'react-router-dom'

import { connect } from 'react-redux'
import { setPopup } from '~/client/redux/actions/popupsActions'
import { NOTIFIER, VIDEOPLAYER } from '~/client/redux/popup-types'

import {
  addToWatchlist,
  removeFromWatchlist
} from '../../redux/actions/userRestActions'
import loadable from '@loadable/component'

function VideoTile ({
  gist,
  variant = 'short',
  ageRestriction,
  featureText,
  color,
  s,
  seasons,
  openAsPopup = false,
  onTileClick,
  mweb,
  user,
  setPopup,
  addToWatchlist,
  removeFromWatchlist,
  videoDetailsData,
  videoplayer,
  metadataMap = false,
  width,
  showProgressBar = false,
  style,
  deviceLimitCrossed,
  handleSearchClose,
  searchpopup,
  discoverpopup,
  episodeTile = false,
  videoTileHeading
}) {
  const variantToSize = {
    short: '_16x9',
    long: '_9x16',
    cube: '_1x1',
    circle: '_1x1',
    genre: '_16x9',
    'extra-wide': '_32x9',
    landscape: '_16x9',
    // portrait: '_9x16',
    portrait: '_3x4',
    portrait_alt: '_9x16'
  }

  const imageRatioForSmallSizeLoading = {
    _16x9: 1.77,
    _9x16: 0.677,
    _3x4: 0.677,
    // _9x16: 0.56,
    _1x1: 1,
    _32x9: 3.55
  }

  const VideoTileHover = loadable(() => import('./VideoTileHover'))
  const VideoTileLandscapeInfo = loadable(
    () => import('./VideoTileLandscapeInfo')
  )

  const size = s.thumbnailType
  // console.log('size', size, variantToSize[size], variant);
  // const shortOrlong =
  //   gist && gist.imageGist[variantToSize[size]] ? 'short' : 'long';
  const shortOrlong =
    size === 'cube' ? null : size == 'landscape' ? 'short' : 'long'

  let imageReducePercentage = 0.1
  // if (lazy) imageReducePercentage = 0.1;
  lazy ? (imageReducePercentage = 1) : (imageReducePercentage = 1)

  const imageSizeRef = useRef({
    width: mweb
      ? size === 'landscape'
        ? 340
        : 220
      : 360 * imageReducePercentage,
    height:
      (mweb
        ? size === 'landscape'
          ? 340
          : 220
        : 360 * imageReducePercentage) /
      imageRatioForSmallSizeLoading[variantToSize[size]]
  })

  const [isImageLoaded, setIsImageLoaded] = useState(false)
  // const [showDecription, setShowDescription] = useState(false)
  const isVideoPlayerRef = useRef(false)
  isVideoPlayerRef.current = videoplayer
  const [isAddToWatch, setIsAddToWatch] = useState(false)

  const badgeImageUrl =
    gist &&
    gist?.badgeImages &&
    (gist?.badgeImages[variantToSize[size]] ||
      gist?.badgeImages[variantToSize[size + '_alt']] ||
      gist?.badgeImages[variantToSize[variant]]) +
      `?impolicy=resize&w=${imageSizeRef.current.width}&h=${imageSizeRef.current.height}`

  const [badgeVariant, setBadgeVariant] = useState()
  const [imageVariant, setImageVariant] = useState()

  const [imageUrl, setImageUrl] = useState(
    gist &&
      gist.imageGist &&
      (gist.imageGist[variantToSize[size]] ||
        gist.imageGist[variantToSize[size + '_alt']] ||
        gist.imageGist[variantToSize[variant]]) +
        `?impolicy=resize&w=${imageSizeRef.current.width}&h=${imageSizeRef.current.height}`
  )

  // const imageUrl = gist && gist.imageGist[];
  const navigate = useNavigate()
  const videoTileShimmerRef = useRef(null)
  const originalObjectId = gist?.originalObjectId
  const contentId = gist?.id

  useEffect(() => {
    const watchlist = user?.watchlist || []
    const isAddedToWatchlist = watchlist.some(
      (id) => id === contentId || id === originalObjectId
    )
    setIsAddToWatch(isAddedToWatchlist)
    //
    if (!lazy) return
    let timerID2
    imageReducePercentage = 0.75

    // To get non pixalated images

    // if (screen.width <= 768) {
    //   if (size == 'landscape') {
    //     imageSizeRef.current = {
    //       width: 200,
    //       height: 178 / imageRatioForSmallSizeLoading[variantToSize[size]]
    //     }
    //   } else {
    //     imageSizeRef.current = {
    //       width: 200,
    //       height: 100 / imageRatioForSmallSizeLoading[variantToSize[size]]
    //     }
    //   }
    // }
    // else {
    //   imageSizeRef.current = {
    //     width: 360 * imageReducePercentage,
    //     height:
    //       (360 * imageReducePercentage) /
    //       imageRatioForSmallSizeLoading[variantToSize[size]]
    //   }
    // }

    const finalImageUrl =
      gist &&
      gist.imageGist &&
      (gist.imageGist[variantToSize[size]] ||
        gist.imageGist[variantToSize[size + '_alt']] ||
        gist.imageGist[variantToSize[variant]]) +
        `?impolicy=resize&w=${imageSizeRef.current.width}&h=${imageSizeRef.current.height}`

    const imageLoader = new Image()
    imageLoader.src = finalImageUrl

    imageLoader.onload = () => {
      videoTileShimmerRef.current &&
        videoTileShimmerRef.current.classList.remove('shimmer-animation')
      setIsImageLoaded(true)
      setTimeout(() => {
        setImageUrl(finalImageUrl)
      }, 1000)
    }

    imageLoader.onerror = () => {
      timerID2 = setTimeout(() => {
        videoTileShimmerRef.current &&
          videoTileShimmerRef.current.classList.remove('shimmer-animation')
      }, 5000)
    }

    const fileBadgename = badgeImageUrl?.split('/').pop()
    const fileImageName = imageUrl.split('/').pop()

    // Check if the filename or any other part of the URL contains "3x4"
    if (fileBadgename?.includes('3x4')) {
      setBadgeVariant('landscape')
    } else if (fileBadgename?.includes('16x9')) {
      setBadgeVariant('portrait')
    }

    if (fileImageName.includes('3x4')) {
      setImageVariant('landscape')
    } else if (fileImageName.includes('16x9')) {
      setImageVariant('portrait')
    }

    return () => {
      clearTimeout(timerID2)
    }
  }, [])

  useEffect(() => {
    const watchlist = user?.watchlist || []
    const isAddedToWatchlist = watchlist.some(
      (id) => id === contentId || id === originalObjectId
    )
    setIsAddToWatch(isAddedToWatchlist)
  }, [user?.watchlist])

  // check watched

  const { contentType = '' } = gist || {}

  const watchListoptions = {
    contentId,
    contentType,
    position: 1,
    userId: user?.userId,
    videoData: videoDetailsData,
    user
  }

  // console.log({ showMinusIcon });
  // console.log({ isAddedToWatchlist, contentId });
  const attrs = {
    [isAddToWatch ? 'data-minus-icon' : 'data-plus-icon']:
      JSON.stringify(watchListoptions)
  }

  const onPlayButtonClick = () => {
    localStorage.setItem('Play_Source', videoTileHeading)
    let videoId = gist.id
    if (gist.contentType === 'SERIES' && seasons[0]?.episodes[0]?.id) {
      videoId = seasons[0]?.episodes[0]?.id
    }

    if (videoId) {
      setPopup(VIDEOPLAYER, {
        contentData: { videoId, gist, seasons, metadataMap }
      })
    } else {
      setPopup(NOTIFIER, {
        message: 'Something went wrong! Please try again after sometime.'
      })
    }
    // handlePlayClick(slideData, slideData.gist.id, null);
  }

  // fix for season image change when changing season on video detail page
  useEffect(() => {
    setImageUrl(
      gist &&
        gist.imageGist &&
        (gist.imageGist[variantToSize[size]] ||
          gist.imageGist[variantToSize[size + '_alt']] ||
          gist.imageGist[variantToSize[variant]]) +
          `?impolicy=resize&w=${imageSizeRef.current.width}&h=${imageSizeRef.current.height}`
    )
  }, [
    gist.imageGist[variantToSize[size]],
    gist.imageGist[variantToSize[size + '_alt']],
    gist.imageGist[variantToSize[variant]]
  ])

  const handleAddAndRemoveToWatchList = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (user.auth) {
      !isAddToWatch
        ? addToWatchlist(watchListoptions, user)
        : removeFromWatchlist(watchListoptions, user)
    } else {
      setPopup('login')
    }
  }

  const handlePlayButtonClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    localStorage.setItem('Play_Source', videoTileHeading)
    onPlayButtonClick(videoDetailsData)
  }

  // to minimize the lenght of video title
  let videoTitle
  if (mweb && gist?.title?.length > 12) {
    videoTitle = gist ? gist.title.substr(0, 12).concat('...') : ''
  } else if (!mweb && gist?.title?.length > 22) {
    videoTitle = gist ? gist.title.substr(0, 22).concat('...') : ''
  } else {
    videoTitle = gist ? gist.title : ''
  }

  const getBackGroundImage = (
    imageVariant,
    badgeVariant,
    variant,
    badgeImageUrl,
    imageUrl
  ) =>
    `${
      variant === 'search' ||
      variant === 'season' ||
      (variant === 'short' &&
        imageVariant === 'landscape' &&
        badgeVariant === 'landscape') ||
      (variant === 'short' &&
        imageVariant === 'portrait' &&
        badgeVariant === 'portrait')
        ? `url(${badgeImageUrl}), url(${imageUrl}) ,`
        : variant === 'short'
          ? `url(${imageUrl}) ,`
          : ''
    } linear-gradient(#212121, #212121)`

  const tileStyleProps = (shortOrlong) => ({
    width: '100%',
    paddingBottom: shortOrlong === 'long' ? '147%' : '56.25%',
    backgroundSize: 'cover',
    position: 'relative'
  })

  return (
    <div
      className={
        'video-tile-container ' +
        (variant === 'search' && 'video-tile-search-variant ') +
        (variant === 'cube' && ' video-tile-cube ')
      }
    >
      <div
        className={`video-tile-wrapper video-tile-${shortOrlong} ${size} ${
          variant === 'season' && 'video-tile-season'
        } ${!isImageLoaded && 'video-tile-no-hover(remove-me-if-needed)'}`}
        style={variant === 'short' ? { ...style } : {}}
      >
        <div className='video-tile-parent swiper-lazy'>
          <Link
            to={
              deviceLimitCrossed
                ? '/'
                : gist && gist.permalink && gist.permalink
            }
            onClick={(e) => {
              if (deviceLimitCrossed) {
                handleSearchClose()
              } else {
                if (openAsPopup) {
                  e.preventDefault()
                  if (onTileClick && width === 175 && width) {
                    onTileClick()
                    return
                  }
                  if (onTileClick) {
                    if (videoDetailsData?.title) {
                      onTileClick()
                      return
                    } else return navigate(gist?.permalink)
                  }
                }
                if (searchpopup && gist && gist?.permalink) {
                  if (searchpopup) setPopup('searchpopup')
                  return navigate(gist?.permalink)
                }
                if (discoverpopup && gist && gist?.permalink) {
                  if (discoverpopup) setPopup('discoverpopup')
                  return navigate(gist?.permalink)
                }
              }
            }}
          >
            <div
              // ref={carouselSlideDetailContainerRef}
              className='video-tile-image'
              data-background={imageUrl}
              style={{
                ...style,
                backgroundImage: getBackGroundImage(
                  imageVariant,
                  badgeVariant,
                  variant,
                  badgeImageUrl,
                  imageUrl
                ),
                ...tileStyleProps(shortOrlong)
              }}
              title={gist.title}
              alt={gist.title}
              data-badgeimageurl={badgeImageUrl}
            >
              {
                // isImageLoaded ?
                <>
                  {featureText && (
                    <div className='video-tile-new-label'>{featureText}</div>
                  )}
                  {showProgressBar && (
                    <div
                      className='video-tile-progress'
                      data-percentage={gist.watchedPercentage + '%'}
                      style={{ '--progress-width': gist.watchedPercentage }}
                    >
                      <span
                        style={{
                          width:
                            gist.watchedPercentage > 90
                              ? 100 + '%'
                              : gist.watchedPercentage + '%'
                        }}
                      />
                    </div>
                  )}
                  {typeof window !== 'undefined' && (
                    <VideoTileHover
                      gist={gist}
                      ageRestriction={ageRestriction}
                      attrs={attrs}
                      handleAddAndRemoveToWatchList={
                        handleAddAndRemoveToWatchList
                      }
                      handlePlayButtonClick={handlePlayButtonClick}
                      isAddToWatch={isAddToWatch}
                      width={width}
                    />
                  )}
                </>
              }
            </div>
          </Link>

          <div className='video-tile-info'>
            <div className='video-tile-action-container'>
              <div
                className='video-tile-title'
                style={{ '--genre-color': color ? `${color}6b` : '#b71c1c6b' }}
              >
                {videoTitle}
                {ageRestriction && (
                  <div className='video-tile-age-rating'>{ageRestriction}</div>
                )}
              </div>

              {size === 'landscape' && typeof window !== 'undefined' && (
                <VideoTileLandscapeInfo
                  attrs={attrs}
                  isAddToWatch={isAddToWatch}
                  onPlayButtonClick={onPlayButtonClick}
                  variant={variant}
                  videoDetailsData={videoDetailsData}
                  fallback={null}
                />
              )}
            </div>
            {mweb && episodeTile && gist?.runtime && (
              <div className='episode-runtime'>
                {' '}
                {Math.round(gist.runtime / 60)} mins
              </div>
            )}

            <ul className='video-tile-feature-list'>
              {gist && gist.runtime && (
                <li>{Math.round(gist.runtime / 60)} mins</li>
              )}
              {gist && gist.year && <li>{gist.year}</li>}
              {gist && gist.primaryCategory && gist.primaryCategory.title && (
                <li>{gist.primaryCategory.title}</li>
              )}
            </ul>
            {gist && gist.description && (
              <div className='video-tile-description'>{gist.description}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  (state) => {
    return {
      user: state.user,
      mweb: state.page.mweb,
      videoplayer: state.popups?.videoplayer,
      searchpopup: state.popups?.search,
      discoverpopup: state.popups?.discover
    }
  },
  { setPopup, addToWatchlist, removeFromWatchlist }
)(VideoTile)
