import React, { useRef, useState, useEffect, useMemo } from 'react'
import './styles.css'
import VideoTile from '../VideoTile'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Lazy } from 'swiper'
import { connect } from 'react-redux'
import { fetchHistory } from '~/client/redux/actions/userEditActions'
import loadable from '@loadable/component'

function VideoTiles ({
  moduleData,
  s,
  variant = 'short',
  mweb,
  isTablet,
  showProgressBar = false,
  disablePopup
}) {
  SwiperCore.use([Navigation, Lazy])
  if (!moduleData) return null
  const VideoDetailsPopup = useMemo(
    () => loadable(() => import('../../popups/VideoDetailsPopupV2')),
    []
  )

  const { contentData: tiles, title } = moduleData
  const videotilewidth = useRef()
  const { desktop = 5, mobile = 3, tablet = 4 } = s?.columns || {}
  // console.log(s.thumbnailType,s.columns)

  if (!tiles || !tiles.length) {
    return null
  }

  const isLandscape = s && s.thumbnailType === 'landscape'

  const firstClick = useRef(false)
  const [tilesData, setTilesData] = useState([])
  const [showVideoPopup, setShowVideoPopup] = useState(false)
  const [showTiles, setShowTiles] = useState(
    mweb ? (isLandscape ? 3 : 4) : isLandscape ? 6 : 9
  )

  const [initActivePopupSlide, setInitActivePopupSlide] = useState(0)
  const videoTilesRef = useRef()
  const videoTileOffset = useRef(0)
  const videotTileLimit = !isLandscape ? 10 : 6
  const [tileLimit, setTileLimit] = useState(!isLandscape ? 10 : 6)
  const canScrollToRight = useRef(false)
  const videoTileControlsContainer = useRef(null)
  const [dynamicWidth, setDynamicWidth] = useState(mweb ? 110 : 182)
  const [sliderEnd, setSliderEnd] = useState(false)

  const shouldUpdateTileData = useRef(true)
  const loadMoreTileData = useRef(true)

  const [disableLeftArrow, setDisableLeftArrow] = useState(false)
  const [disableRightArrow, setDisableRightArrow] = useState(false)

  const [isSSR, setIsSSR] = useState(true)

  const swiperRef = useRef(null)

  if (shouldUpdateTileData.current) {
    if (tiles) {
      const _tiles = [...tiles]
      _tiles.length = showTiles
      setTilesData(_tiles)
    }
    // setTilesData(tiles.slice(videoTileOffset.current, videotTileLimit));
    shouldUpdateTileData.current = false
  }

  useEffect(() => {
    // setTimeout(() => {
    setTilesData(tiles)
    // }, 5000);
  }, [tiles])

  useEffect(() => {
    // shouldUpdateTileData.current = true;
    setIsSSR(false)

    if (swiperRef.current && swiperRef.current.swiper && !isTablet) {
      swiperRef.current.swiper?.navigation?.nextEl?.classList?.add('top--6')
      swiperRef.current.swiper?.navigation?.prevEl?.classList?.add('top--6')
    } else if (swiperRef.current && swiperRef.current.swiper && isLandscape) {
      swiperRef.current.swiper?.navigation?.nextEl?.classList?.add('top-20')
      swiperRef.current.swiper?.navigation?.prevEl?.classList?.add('top-20')
    }
    // commenting this because its calling api so many times  and fetchhistory is already called  in app.js
    // if (
    //   !(
    //     user.watchHistory &&
    //     user.watchHistory.records &&
    //     user.watchHistory.records.length
    //   )
    // ) {
    //   fetchHistory();
    // }

    if (window.innerWidth > 1600) {
      videoTileOffset.current += videotTileLimit
      // setVideoTileOffset((prevOffset) => prevOffset + videotTileLimit);
      const newVideoTileData = tiles.slice(
        videoTileOffset.current,
        videoTileOffset.current + videotTileLimit
      )
      // console.log({title, tiles, newVideoTileData, "videoTileOffset.current": videoTileOffset.current, "videoTileOffset.current + videotTileLimit": videoTileOffset.current + videotTileLimit });
      if (!newVideoTileData.length) {
        // videoTilesRef.current.removeEventListener('scroll', loadMoreTiles);
        loadMoreTileData.current = false
        // console.log('listener removed!', title);
        if (!(scrollWidth > clientWidth)) {
          videoTileControlsContainer.current &&
            videoTileControlsContainer.current.remove()
        }
        // return;
      } else {
        // console.log("++++++++++ ADDED NEW TILES ++++++++ "+title, newVideoTileData);
        setShowTiles(showTiles + (mweb ? 4 : 7))
        setTimeout(() => {
          setTilesData((tilesData) => [...tilesData, ...newVideoTileData])
        }, 500)
      }
    }

    const scrollLeft = videoTilesRef.current.scrollLeft
    const scrollWidth = videoTilesRef.current.scrollWidth
    const clientWidth = videoTilesRef.current.clientWidth

    if (scrollWidth <= window.innerWidth) {
      setDisableRightArrow(true)
    }
    // const increaseTilesLimit = () => {
    //   if(sliderEnd) {
    //     setTileLimit(tileLimit + tileLimit)
    //   }
    // }
    const loadMoreTiles = () => {
      const scrollLeft = videoTilesRef.current.scrollLeft
      const scrollWidth = videoTilesRef.current.scrollWidth
      const clientWidth = videoTilesRef.current.clientWidth
      if (scrollLeft > 0) {
        // console.log('enable left arrow');/
        setDisableLeftArrow(false)
      } else {
        setDisableLeftArrow(true)
        // console.log('disable left arrow');
      }

      if (scrollLeft + clientWidth == scrollWidth) {
        setDisableRightArrow(true)
      } else {
        setDisableRightArrow(false)
      }

      if (scrollWidth - scrollLeft <= clientWidth && loadMoreTileData.current) {
        // console.log('in the listener');
        videoTileOffset.current += videotTileLimit
        // setVideoTileOffset((prevOffset) => prevOffset + videotTileLimit);
        const newVideoTileData = tiles.slice(
          videoTileOffset.current,
          videoTileOffset.current + videotTileLimit
        )
        // console.log({ tiles });
        if (!newVideoTileData.length) {
          // videoTilesRef.current.removeEventListener('scroll', loadMoreTiles);
          loadMoreTileData.current = false

          return
        }
        setTilesData((tilesData) => [...tilesData, ...newVideoTileData])
      }
    }

    videoTilesRef?.current?.addEventListener?.('scroll', loadMoreTiles)
    // let scrollLeft = videoTilesRef.current.scrollLeft;
    if (scrollLeft == 0) {
      setDisableLeftArrow(true)
      //
    }

    return () => {
      videoTilesRef?.current?.removeEventListener?.('scroll', loadMoreTiles)
      shouldUpdateTileData.current = true
    }
  }, [])

  useEffect(() => {
    if (firstClick.current) {
      videoTileOffset.current += videotTileLimit
      const newVideoTileData = tiles.slice(
        videoTileOffset.current,
        videoTileOffset.current + videotTileLimit
      )
      if (newVideoTileData.length) {
        setTilesData((tilesData) => [...tilesData, ...newVideoTileData])
        canScrollToRight.current = true
      }

      if (tilesData.length === tiles.length) {
        const videoTilesScrollValue =
          videoTilesRef.current.scrollLeft + screen.width - 260
        videoTilesRef.current.scrollTo({
          top: 0,
          left: videoTilesScrollValue,
          behavior: 'smooth'
        })
      }
    }
  }, [showTiles])

  const mWebTilesCount = () => {
    if (videoTilesRef.current) {
      if (
        videoTilesRef.current.scrollLeft + videoTilesRef.current.offsetWidth >=
        videoTilesRef.current.scrollWidth - 25
      ) {
        setShowTiles(showTiles + (mweb ? 4 : 7))
      }
    }
  }

  useEffect(() => {
    if (canScrollToRight.current) {
      const videoTilesScrollValue =
        videoTilesRef.current.scrollLeft + screen.width - 260
      videoTilesRef.current.scrollTo({
        top: 0,
        left: videoTilesScrollValue,
        behavior: 'smooth'
      })
      canScrollToRight.current = false
    }
  })
  useEffect(() => {
    setDynamicWidth(
      parseInt(videotilewidth.current.style.width.match(/(\d+)/))
    )
  }, [])

  // making helper array because tiles were repeating in arrays
  const helperArray = []

  return (
    <div
      className={`video-tiles-parent video-tiles-${variant} video-tiles-${s.thumbnailType}`}
    >
      <div className='video-tiles-heading'>{title}</div>
      <div style={{ position: 'relative' }}>
        <div
          className={`video-tiles ${
            title === 'hoichoi Top 10' ? 'marginReset' : ''
          }`}
          ref={videoTilesRef}
          onScroll={() => mWebTilesCount()}
        >
          <Swiper
            className='video-tiles-swiper'
            onSlideChangeTransitionEnd={(swiper) => {
              setSliderEnd(swiper.isEnd)
            }}
            navigation={!mweb}
            spaceBetween={isTablet || mweb ? 5 : 8}
            breakpoints={{
              1400: {
                slidesPerView: 'auto',
                slidesPerGroup: desktop || 5
              },
              1200: {
                slidesPerView: 'auto',
                slidesPerGroup: desktop || 5
              },
              1000: {
                slidesPerView: 'auto',
                slidesPerGroup: desktop || 5
              },
              800: {
                slidesPerView: 'auto',
                slidesPerGroup: tablet || 4
              },
              600: {
                slidesPerView: 'auto',
                slidesPerGroup: tablet || 4
              },
              500: {
                slidesPerView: 'auto',
                slidesPerGroup:
                  s.thumbnailType === 'landscape' ? 2 : mobile || 3
              },
              300: {
                slidesPerView: 'auto',
                slidesPerGroup:
                  s.thumbnailType === 'landscape' ? 2 : mobile || 3
              }
            }}
            ref={swiperRef}
            lazy
            onLazyImageReady={(swiper, slideEl, imageEl) => {
              const badgeImageUrl = imageEl.dataset.badgeimageurl

              if (badgeImageUrl) {
                imageEl.style.backgroundImage = `url(${badgeImageUrl}), ${imageEl.style.backgroundImage}`
              }
            }}
          >
            {tilesData.map((tile, index) => {
              // putting if else //  because tiles were repeating in arrays
              // if (index < showTiles)
              if (helperArray.includes(tile.gist.id)) {
              } else {
                helperArray.push(tile.gist.id)
                return (
                  <SwiperSlide
                    className={
                      isSSR
                        ? mweb
                          ? 'video-tiles-swiper-ssr-mweb-width'
                          : 'video-tiles-swiper-ssr-width'
                        : ''
                    }
                    ref={videotilewidth}
                    key={index}
                  >
                    <VideoTile
                      // eslint-disable-next-line react/no-array-index-key
                      key={`videotile-${index}-${variant}`}
                      videoDetailsData={tile}
                      {...{ ...tile, variant, s }}
                      openAsPopup={!mweb}
                      onTileClick={() => {
                        localStorage.setItem('Play_Source', title)
                        setInitActivePopupSlide(index)
                        setShowVideoPopup(true)
                      }}
                      width={dynamicWidth}
                      videoTileHeading={title}
                      // lazyload={lazyload}
                      showProgressBar={showProgressBar}
                      style={{
                        transformOrigin:
                          (index + 1) % (desktop || 8) === 0
                            ? ' center right '
                            : 'unset'
                      }}
                    />
                  </SwiperSlide>
                )
              }
              // else return null;
            })}
          </Swiper>
        </div>
      </div>
      <div className='video-tiles-popup-container'>
        {showVideoPopup && !disablePopup && (
          <VideoDetailsPopup
            videoDetailsData={tilesData}
            openVideoPopup={showVideoPopup}
            onVideoPopupClosed={(videoPopupState) => {
              setShowVideoPopup(videoPopupState)
            }}
            initActiveSlide={initActivePopupSlide}
            setInitActiveSlide={setInitActivePopupSlide}
          />
        )}
      </div>
    </div>
  )
}

export default connect(
  (state) => {
    return {
      user: state.user,
      mweb: state.page.mweb,
      isTablet: state.page.isTablet
    }
  },
  { fetchHistory }
)(VideoTiles)
